import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  pt: 4,
  px: 6,
  pb: 3,
};

const EditLawyer = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const [picture, setPicture] = useState([]);
  const history = useNavigate();

  console.log(id);
  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  const submitLawyer = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", picture.image);

    axios.post(`api/update-lawyer/${id}`, formData).then((res) => {
      if (res.data.status === 200) {
        console.log("lawyer updated successfully");
        history("/");
      }
    });
  };

  return (
    <div>
      {/* Open Modal  */}
 
      <Box sx={style}>
          <form
            className="flex flex-col items-center align-center space-x-6"
            onSubmit={submitLawyer}
            encType="multipart/form-data"
          >
            <label className="block">
              <span className="sr-only">{t('description.modalFile')}</span>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImage}
                className="block w-full text-sm text-gray-600
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:text-gray-600 file:font-semibold
      file:cursor-pointer file:bg-gray-100
      "
              />
            </label>
            <input
              className="text-sm cursor-pointer text-white mt-4 block border-0 p-2 rounded bg-lime-700"
              type="submit"
              value={t('description.modalSubmit')}
              style={{  backgroundColor: "#16a94e"}}
            />
          </form>
        </Box>

      {/* End Modal  */}

      {/* <form onSubmit={submitLawyer} encType="multipart/form-data">
        <label htmlFor="image">Image</label>
        <input
          type="file"
          accept="image/*"
          name="image"
          onChange={handleImage}
        />
        <br />
        <input type="submit" value="Submit" />
      </form> */}
    </div>
  );
};

export default EditLawyer;
