import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VcardRecto from "./Components/VcardRecto";
import html2canvas from "html2canvas";
import Canvas2Image from "canvas2image";
import jsPDF from "jspdf";
import "./styles/Vcard.css";
import { MdOutlinePhoneAndroid, MdEmail, MdArrowBackIos } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import signature from "./assets/signature.png";
import noteFr from "./assets/Back note FR.png";
import logoBarreau from "./assets/logoBarreau.png";

import QRCode from "react-qr-code";
import { Button } from "@mui/material";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import domtoimage from "dom-to-image";
import { useTranslation } from "react-i18next";

const Viewlawyer = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  // console.log(i18n.resolvedLanguage);
  const [lawyer, setlawyer] = useState([]);
  const navigate = useNavigate();
  var presDate;
  var expi = 3;

  function formatDate(date = new Date(), expi) {
    var day, month, year;
    if (expi === 3) {
      year = Math.floor(parseFloat(date.getFullYear())) + expi;
      month = date.getMonth() + 1;
      day = date.getDate();
    } else {
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return day + "/" + month + "/" + year;
  }

  useEffect(() => {
    axios.get(`api/get-lawyer/${id}`).then((res) => {
      if (res.data.status === 200) {
        setlawyer(res.data.lawyer);
      }
    });
  }, [id]);

  presDate = new Date(lawyer.prestationDate);

  const generatePDF = async (fileName) => {
    const doc = new jsPDF('landscape', 'px', [720, 450]);

    // Convertir le recto en image
    const frontCanvas = await html2canvas(document.getElementById('card-front'), { scale: 2 });
    const frontImgData = frontCanvas.toDataURL('image/png');
    doc.addImage(frontImgData, 'PNG', 0, 0, 720, 450);

    // Ajouter une nouvelle page pour le verso
    doc.addPage();

    // Convertir le verso en image
    const backCanvas = await html2canvas(document.getElementById('card-back'), { scale: 2 });
    const backImgData = backCanvas.toDataURL('image/png');
    doc.addImage(backImgData, 'PNG', 0, 0, 720, 450);

    // Sauvegarder le PDF
    doc.save(`${fileName}.pdf`);
  };


  return (
    <div className="bg-slate-50 w-screen h-screen flex overflow-y-scroll overflow-x-hidden">
      {/* sibebar  */}
      <div className="flex flex-col bg-clip-border rounded-xl bg-white h-full w-full max-w-[20rem]  p-4 shadow-xl shadow-blue-gray-900/5 fixed">
        <div className="flex flex-wrap justify-center mt-10">
          <div className="flex w-full">
            <div className="ml-4 mt-12 pr-8 cursor-pointer">
              <MdArrowBackIos
                color="#9e9e9e"
                size={25}
                onClick={() => navigate("/")}
              />
            </div>
            {/* <div className="flex w-32 px-4 align-center"> */}
            <div className="flex justify-center items-center w-32">
              {lawyer.image !== null ? (
                <img
                  src={`https://app-api.barreaucameroun.org/public/storage/profile_images/${lawyer.image}`}
                  alt=""
                  className="shadow rounded-full max-w-full h-auto align-middle border-none"
                />
              ) : (
                <span className="inline-block p-7 rounded-full bg-gray-100">
                  <FaUser color="#727565" size={40} />
                </span>
              )}
            </div>
          </div>
          <div className="mt-8">
            <div className="text-center ">
              <h2 className="text-black text-lg font-bold w-80 px-2">
                {lawyer.name}
              </h2>
              {lawyer.account_type === "lawyer" ? (
                <p className="text-gray-400 text-sm">
                  {t("description.statut")} | {lawyer.matricule}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="w-full mt-8 rounded-md p-3 bg-gray-100 ">
            <div className="text-center text-sm">
              <p className="font-semibold text-black">
                {t("description.inscrip")}:{" "}
                {lawyer.prestationDate && formatDate(presDate)}
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-8 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-6">
              <MdOutlinePhoneAndroid color="#9e9e9e" size={20} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">{lawyer.phone}</p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-4 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-6">
              <MdEmail color="#9e9e9e" size={20} />
            </div>
            <div className="text-sm">
              {lawyer.email !== "" ? (
                <p className="font-semibold">{lawyer.email}</p>
              ) : (
                <p className="font-semibold">{t("description.email")}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row w-full mt-4 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-6">
              <FaLocationDot color="#9e9e9e" size={20} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">{lawyer.town}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Vcard */}
      <div className="h-full w-full flex gap-2 flex-col items-center ml-[12rem] mt-[3rem]">
        <div>
          {/* Recto  */}
          <div id="card-front" className="backImg">
            <div className="flex  justify-center logoCard">
              <img
                src={logoBarreau}
                alt=""
                style={{ width: "15.5%", height: "15.5%" }}
              />
            </div>
            <div className="flex justify-center items-center imgCard">
              {lawyer.image !== null ? (
                <img
                  src={`https://card-barreau.altechs.africa/images/${lawyer.image}`}
                  // src={`https://app-api.barreaucameroun.org/public/storage/profile_images/${lawyer.image}`}
                  alt=""
                  className="shadow rounded-full max-w-full h-auto align-middle border-none"
                />
              ) : (
                <span className="inline-block p-12 rounded-full bg-gray-100">
                  <FaUser color="#727565" size={59} />
                </span>
              )}
            </div>
            <div className="matricule" style={{ width: "100px" }}>
              <p
                className="text-white font-argentum_reg font-medium"
                style={{
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                }}
              >
                MATRICULE
              </p>
              <p
                className="text-white font-argentum font-extrabold text-left"
                style={{
                  fontSize: "16px",
                  lineHeight: "17px",
                  letterSpacing: "1.3px",
                }}
              >
                {lawyer.matricule}
              </p>
            </div>
            <div className="inscription" style={{ width: "100%" }}>
              <p
                className="text-white font-argentum_reg text-right font-medium"
                style={{
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                }}
              >
                INSCRIPTION
              </p>
              <p
                className=" text-white font-argentum font-extrabold text-right"
                style={{
                  fontSize: "16px",
                  lineHeight: "17px",
                  letterSpacing: "1.3px",
                }}
              >
                {lawyer.prestationDate && formatDate(presDate)}
              </p>
            </div>

            <div className="mt-4 nameLawyer">
              {lawyer.name && lawyer.name.length < 30 ? (
                <p
                  className="flex font-argentum font-extrabold justify-center"
                  style={{ fontSize: "23px", lineHeight: "32px" }}
                >
                  {lawyer.language === "fr" ? "Me " + lawyer.name : lawyer.name + " Esq."}
                </p>
              ) : lawyer.name && lawyer.name.length < 37 ? (
                <p
                  className="flex font-argentum font-extrabold justify-center "
                  style={{ fontSize: "19.5px", lineHeight: "32px" }}
                >
                 {lawyer.language === "fr" ? "Me " + lawyer.name : lawyer.name + " Esq."}
                </p>
              ) : lawyer.name && lawyer.name.length < 40 ? (
                <p
                  className="flex font-argentum font-extrabold justify-center "
                  style={{ fontSize: "18px", lineHeight: "32px" }}
                >
                 {lawyer.language === "fr" ? "Me " + lawyer.name : lawyer.name + " Esq."}
                </p>
              ) : (
                <p
                  className="flex font-argentum font-extrabold justify-center "
                  style={{ fontSize: "16px", lineHeight: "32px" }}
                >
                 {lawyer.language === "fr" ? "Me " + lawyer.name : lawyer.name + " Esq."}
                </p>
              )}
              <p
                className="flex font-argentum font-normal text-black justify-center"
                style={{ fontSize: "19px", lineHeight: "28px" }}
              >
                {lawyer.language === "fr" ? "Avocat" : "Lawyer"}
              </p>
            </div>
          </div>
        </div>

        {/* <div>
          <Button
            onClick={() => exportR(`Recto_${lawyer.name}`)}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#16a94e",
              margin: "6px",
              textTransform: "capitalize",
            }}
          >
            Télécharger
          </Button>
        </div> */}

        {/* Verso  */}
        <div className="">
          <div id="card-back" className="backImgVerso">
            <div className="emission">
              <p
                className="text-white font-argentum_reg font-medium"
                style={{
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                }}
              >
                EMISSION
              </p>
              <p
                className="text-white font-argentum font-extrabold "
                style={{
                  fontSize: "16px",
                  lineHeight: "17px",
                  letterSpacing: "1.3px",
                }}
              >
                {formatDate()}
              </p>
            </div>
            <div className="expiration" style={{ width: "100%" }}>
              <p
                className="text-white font-argentum_reg text-right font-medium"
                style={{
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                }}
              >
                EXPIRATION
              </p>
              <p
                className="text-white font-argentum font-extrabold text-right"
                style={{
                  fontSize: "16px",
                  lineHeight: "17px",
                  letterSpacing: "1.3px",
                }}
              >
                {formatDate(new Date(), expi)}
              </p>
            </div>
            <div className="flex justify-center qrCodeBox">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                value={`https://app-cba.altechs.africa/lawyers/${lawyer.matricule}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div
            style={
              lawyer.language === "fr"
                ? { marginTop: "0px" }
                : { marginTop: "17px", marginLeft: "8px" }
            }
            >
              {lawyer.language === "fr" ? (
                <div>
                  <div className="flex flex-col noteFr">
                    <p
                      className="text-black italic font-extrabold font-argentum_italic w-46"
                      style={{
                        fontSize: "16px",
                        lineHeight: "18px",
                        letterSpacing: "1px",
                      }}
                    >
                      Cette carte est la propriété du Barreau du
                      <br /> Cameroun. Si elle est trouvée n'importe où,
                      <br /> veuillez la renvoyer à
                    </p>
                    <p
                      className="text-black italic font-argentum_italic font-black w-full"
                      style={{
                        fontSize: "17px",
                        lineHeight: "20px",
                      }}
                    >
                      Tél. 237 652 70 91 22/237 655 72 36 98
                    </p>
                  </div>

                  <div
                    className="flex flex-col text-right"
                    style={{ width: "100%",  transform:" translate(-47px, 51px)" }}
                  >
                    <p
                      className="text-black italic font-argentum_italic font-black "
                      style={{
                        fontSize: "12px",
                        lineHeight: "12px",
                        // letterSpacing: "1px",
                      }}
                    >
                      Me. MBAH Eric MBAH
                    </p>
                    <p
                      className="text-black italic font-argentum_italic mr-1 font-extrabold"
                      style={{
                        fontSize: "12.3px",
                        lineHeight: "15px",
                      }}
                    >
                      Bâtonnier de l'Ordre
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-col noteEn">
                    <p
                      className="text-black italic font-extrabold font-argentum_italic w-46"
                      style={{
                        fontSize: "16px",
                        lineHeight: "18px",
                        letterSpacing: ".8px",
                      }}
                    >
                      This card is the property of the Cameroon Bar.
                      <br />
                      If found anywhere, please, return to
                    </p>

                    <p
                      className="text-black italic font-argentum_italic font-black w-full"
                      style={{
                        fontSize: "17px",
                        lineHeight: "20px",
                      }}
                    >
                      Tél. +237 652 70 91 22/+237 655 72 36 98
                    </p>
                  </div>

                  <div
                    className="flex flex-col text-right  signName"
                    style={{ width: "100%", transform:" translate(-38px, 51px)"}}
                  >
                    <p
                      className="text-black italic font-argentum_italic font-black"
                      style={{
                        fontSize: "12px",
                        lineHeight: "12px",
                      }}
                    >
                      MBAH Eric MBAH, Esq.
                    </p>
                    <p
                      className="text-black italic mr-4 font-argentum_italic font-extrabold"
                      style={{
                        fontSize: "12.3px",
                        lineHeight: "15px",
                      }}
                    >
                      The Bar President
                    </p>
                  </div>
                </div>
              )}
              <div className="sign">
                <img src={signature} alt="" width="22.4%" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <Button
          onClick={() => generatePDF(`Card_${lawyer.name }`)}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#16a94e",
              marginTop: "6px",
              marginBottom: "15px",
              textTransform: "capitalize",
            }}
          >
            Télécharger
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Viewlawyer;
