import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { FaUser } from "react-icons/fa6";
import { FaEye, FaEdit } from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EditLawyer from "./EditLawyer";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { MenuItem, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  pt: 4,
  px: 6,
  pb: 3,
};

const ViewLawyers = () => {
  const { t, i18n } = useTranslation();
  const [lawyersList, setLawyersList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [picture, setPicture] = useState([]);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    //   axios.get(`api/get-lawyers`).then((res) => {
    //     // if (res.data.status === 200) {
    //         setLawyersList(res.data.lawyers);
    //     // }
    // });
    setLoading(true);
    const data = {
      search: search,
      limit: 50,
    };
    // console.log(data);

    axios.post(`api/get-lawyers`, data).then((res) => {
      if (res.data.status === 200) {
        setLawyersList(res.data.lawyers);
        setLoading(false);
      }
    });
  }, [search]);

  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  // const handleInput = (e) => {
  //   e.persist();
  //   setLanguage({ language: e.target.value });
  // };

  const handleOpen = (id) => {
    // const id = id;
    localStorage.setItem("id_lawyer", id);
    setOpen(true);
  };

  const submitLawyer = (e) => {
    e.preventDefault();
    setLoadingButton(true);
    const formData = new FormData();
    formData.append("search", search);
    formData.append("image", picture.image);
    formData.append("language", language);
    // console.log(language);
    const id = localStorage.getItem("id_lawyer");
    // console.log(id, picture.image);

    axios.post(`api/update-lawyer/${id}`, formData).then((res) => {
      if (res.data.status === 200) {
        setLawyersList(res.data.lawyers);
        console.log("lawyer updated successfully");
        setOpen(false);
        setLoadingButton(false);
      }
    });
  };

  return (
    <div className="container mx-auto px-8 font-poppins mt-8">
      <div className="flex-row flex items-center">
        <form class="max-w-sm w-full mx-auto">
          <label for="simple-search" class="sr-only">
            Search
          </label>
          <div class="relative w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
              placeholder={t("description.searchbar")}
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search !== "" ? (
              <div class="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer">
                <IoMdClose onClick={() => setSearch("")} />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
        <div className="flex gap-4">
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "lowercase",
              backgroundColor: "#16a94e",
            }}
          >
            <Link to={`/add-lawyer`} className="pl-1">
              Ajouter un avocat
            </Link>
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "lowercase",
              backgroundColor: "#16a94e",
            }}
          >
            <Link to={`/add-huissier`} className="pl-1">
              Ajouter un huissier
            </Link>
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "lowercase",
              backgroundColor: "#16a94e",
            }}
          >
            <Link to={`/add-notaire`} className="pl-1">
              Ajouter un notaire
            </Link>
          </Button>
        </div>
      </div>

      {/* Open Modal  */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            className="flex flex-col items-center align-center space-x-6"
            onSubmit={submitLawyer}
            encType="multipart/form-data"
          >
            <label className="block">
              <span className="sr-only">{t("description.modalFile")}</span>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImage}
                className="block w-full text-sm text-gray-600
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:text-gray-600 file:font-semibold
      file:cursor-pointer file:bg-gray-100
      "
              />
            </label>

            <div className="mt-2">
              <select
                id="countries"
                class="border border-gray-300 bg-gray-100 text-gray-900 text-sm rounded-lg block w-full p-1"
                onChange={(e) => setLanguage(e.currentTarget.value)}
                value={language}
                name="language"
              >
                <option selected>Choose a Language</option>
                <option value="fr">Français</option>
                <option value="en">Anglais</option>
              </select>
            </div>

            <button
              type="submit"
              style={{ backgroundColor: "#16a94e" }}
              className="text-sm cursor-pointer text-white mt-2 block border-0 p-2 rounded bg-lime-700"
            >
              {loadingButton === true ? (
                <CgSpinner
                  className="animate-spin h-4 w-24 text-white mx-auto "
                  fontSize={10}
                />
              ) : (
                t("description.modalSubmit")
              )}
            </button>
          </form>
        </Box>
      </Modal>
      {/* End Modal  */}

      {/* Table begin  */}
      <div>
        {loading ? (
          <div className="h-screen flex items-center justify-center">
            <CgSpinner
              className="animate-spin h-10 w-40 text-slate-400 mx-auto "
              fontSize={22}
            />
          </div>
        ) : (
          <TableContainer component={Paper} className="mt-8">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-poppins font-bold">#</span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold"></span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabName")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">E-mail</span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabPhone")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">Actions</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lawyersList &&
                  lawyersList.map((lawyer, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <span className="font-poppins">{index + 1}</span>
                      </TableCell>
                      <TableCell align="">
                        <div className="flex justify-center items-center w-12">
                          {lawyer.image !== null ? (
                            <img
                              // src={`http://localhost:8000/storage/images/${lawyer.image}`}
                              src={`https://app-api.barreaucameroun.org/public/storage/profile_images/${lawyer.image}`}
                              alt=""
                              className="shadow rounded-full max-w-full h-auto align-middle border-none"
                            />
                          ) : (
                            <span className="inline-block p-4 rounded-full bg-gray-100">
                              <FaUser color="#727565" size={20} />
                            </span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{lawyer.name}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{lawyer.email}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{lawyer.phone}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins flex flex-row gap-1">
                          <Button
                            variant="contained"
                            // href="#contained-buttons"
                            size="small"
                            style={{
                              textTransform: "lowercase",
                              backgroundColor: "#16a94e",
                            }}
                          >
                            <FaEye />
                            <Link
                              to={`/view-lawyer/${lawyer.id}`}
                              className="pl-1"
                            >
                              {t("description.view")}
                            </Link>
                          </Button>
                          <Button
                            onClick={() => handleOpen(lawyer.id)}
                            variant="contained"
                            // href="#contained-buttons"
                            size="small"
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            <FaEdit />
                            <span className="pl-1">
                              {t("description.edit")}
                            </span>
                          </Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {/* End Table  */}
    </div>
  );
};

export default ViewLawyers;
